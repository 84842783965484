// @ts-strict-ignore
import React from 'react';
import classNames from 'classnames';

interface TableBuilderTextInputIF {
  value: string;
  onChange: (value: string) => void;
  tabIndex: number;
  extraClassNames?: string;
  style?: any;
}

export const TableBuilderTextInput: React.FunctionComponent<TableBuilderTextInputIF> = (props) => {
  const { value, onChange, tabIndex, extraClassNames, style } = props;

  // React will whine about contentEditable, but React/Bootstrap's own text inputs don't word-wrap or
  // automatically resize.
  return (
    <div
      contentEditable={true}
      suppressContentEditableWarning={true}
      onBlur={(event) => onChange((event.target as HTMLInputElement).textContent)}
      style={style}
      className={classNames('tableBuilderTextInput cursorText lightGreyBorder', extraClassNames)}
      tabIndex={tabIndex}>
      {value}
    </div>
  );
};
