// @ts-strict-ignore
import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { SimpleTableCell, SimpleTableRow, TableBuilderHeaders } from '@/tableBuilder/tableBuilder.store';
import { TableBuilderHeaderRow } from '@/tableBuilder/tableComponents/TableBuilderHeaderRow.molecule';
import { TableBuilderTextHeader } from '@/tableBuilder/tableComponents/TableBuilderTextHeader.atom';
import { TableBuilderTextCell } from '@/tableBuilder/tableComponents/TableBuilderTextCell.atom';
import { TableBuilderDataCell } from '@/tableBuilder/tableComponents/TableBuilderDataCell.atom';
import { TableBuilderRow } from '@/tableBuilder/tableComponents/TableBuilderRow.molecule';
import { RangeExport } from '@/trendData/duration.store';
import { TextHeaderMenuActions } from '@/tableBuilder/tableComponents/TableBuilderTextHeaderMenu.atom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TableBuilderNoResultsRow } from '@/tableBuilder/TableBuilderNoResultsRow.atom';
import { TableColumnFilter } from '@/core/tableUtilities/tables';
import { computeCellStyle, formatHeader, getStripedColor } from '@/utilities/tableBuilderHelper.utilities';
import {
  TableBuilderColumnType,
  TableBuilderHeaderType,
  TableTextFormatterIF,
} from '@/tableBuilder/tableBuilder.constants';
import { COLUMNS_AND_STATS } from '@/trendData/trendData.constants';

interface TableBuilderSimpleTableBProps {
  simpleTableData: SimpleTableRow[];
  isLoading: boolean;
  columns: any[];
  headers: TableBuilderHeaders;
  isTransposed: boolean;
  isStriped: boolean;
  isPresentationMode: boolean;
  isViewOnlyMode: boolean;
  displayRange: RangeExport;
  timezone: { name: string };
  displayMetricOnTrend: (metricId: string, formulaItemId: string, start: number, end: number, event: any) => void;
  setCellText: (key: string, value: string, id?: string) => void;
  setHeaderText: (columnKey: string, text: string) => void;
  setColumnFilter: (key: string, filter: TableColumnFilter) => void;
  hasNumericAndStringSeries: boolean;
  hasOnlyStringSeries: boolean;
  hasNumericAndStringMetrics: boolean;
  hasOnlyStringMetrics: boolean;
  columnToThresholds: Object;
  distinctStringValueMap: { [columnKey: string]: string[] };
  textFormatter: TableTextFormatterIF;
  moveColumn: (key: string, newKey: string) => void;
  canSort: boolean;
  maxSortLevel: number;
  sortByColumn: (key: string, direction: string) => void;
  removeColumn: (key: string) => void;
  darkMode: boolean;
}

export const TableBuilderSimpleTable: React.FunctionComponent<TableBuilderSimpleTableBProps> = ({
  simpleTableData,
  isLoading,
  columns,
  headers,
  isStriped,
  isTransposed,
  isPresentationMode,
  isViewOnlyMode,
  displayRange,
  timezone,
  displayMetricOnTrend,
  setCellText,
  setHeaderText,
  setColumnFilter,
  hasNumericAndStringSeries,
  hasOnlyStringSeries,
  hasNumericAndStringMetrics,
  hasOnlyStringMetrics,
  columnToThresholds,
  distinctStringValueMap,
  textFormatter,
  canSort,
  maxSortLevel,
  moveColumn,
  removeColumn,
  sortByColumn,
  darkMode,
}) => {
  const { t } = useTranslation();

  const canEdit = !isPresentationMode && !isViewOnlyMode;

  let columnIndex = 0;

  const showUnitInSeparateColumn = _.some(columns, (column) => column.key === COLUMNS_AND_STATS.valueUnitOfMeasure.key);

  const getExtraHeaderProps = (column): { isInput: boolean; isStatic?: boolean; textValue: string } => {
    if (column.headerOverridden) {
      const textValue = formatHeader(
        headers,
        column.property,
        displayRange.start.valueOf(),
        displayRange.end.valueOf(),
        timezone,
      );
      return { textValue, isInput: false, isStatic: true };
    }
    // check for undefined. Empty string is OK - the user removed the title
    const textValue =
      column.header ?? (column.type === TableBuilderColumnType.Property ? column.key : t(column.shortTitle));
    return { textValue, isInput: true };
  };

  const getMenuActions = (columnType: string) => {
    if (isPresentationMode) {
      return [];
    }

    if (columnType === TableBuilderColumnType.Text) {
      return isViewOnlyMode
        ? []
        : _.values(TextHeaderMenuActions).filter(
            (action) => !_.includes([TextHeaderMenuActions.Filter, TextHeaderMenuActions.Sort], action),
          );
    } else {
      return isViewOnlyMode
        ? [TextHeaderMenuActions.Filter, TextHeaderMenuActions.Sort]
        : _.values(TextHeaderMenuActions);
    }
  };

  const textHeaders: JSX.Element[] = _.map(columns, (column) => {
    const extraProps = getExtraHeaderProps(column);
    const isStringColumn =
      _.includes(['string', 'assets', 'fullpath'], column.style) ||
      column.isCustomProperty ||
      (hasOnlyStringSeries && column.key === COLUMNS_AND_STATS['statistics.endValue'].key) ||
      (hasOnlyStringMetrics && column.key === COLUMNS_AND_STATS.metricValue.key);

    let columnThresholds;
    if (!!column.stat && !!columnToThresholds[column.stat]) {
      columnThresholds = columnToThresholds[column.stat];
    } else if (column.key === 'metricValue' && !!columnToThresholds['metricValue']) {
      columnThresholds = columnToThresholds['metricValue'];
    } else {
      columnThresholds = undefined;
    }

    // Don't let users filter
    // - stat columns when string and numeric signals are in the table
    // - the metricValue column when string and numeric metrics are in the table
    // - when we are in presentation mode
    const isFilterDisabled =
      (column.key === 'metricValue' && hasNumericAndStringMetrics) ||
      (column.stat && hasNumericAndStringSeries) ||
      isPresentationMode;

    return (
      <TableBuilderTextHeader
        textValue={extraProps.textValue}
        isInput={extraProps.isInput}
        isStatic={extraProps.isStatic}
        onTextChange={(value) => setHeaderText(column.key, value)}
        columnBackgroundColor={column.backgroundColor}
        columnTextAlign={column.textAlign}
        columnTextColor={column.textColor}
        columnTextStyle={column.textStyle}
        headerBackgroundColor={column.headerBackgroundColor}
        headerTextAlign={column.headerTextAlign}
        headerTextColor={column.headerTextColor}
        headerTextStyle={column.headerTextStyle}
        key={columnIndex}
        columnKey={column.key}
        columnIndex={columnIndex++}
        canEdit={canEdit}
        isTransposed={isTransposed}
        sort={{
          canSort,
          canSortDisabledTooltip: 'TABLE_BUILDER.ONLY_ON_TABLE_WITH_ONE_ITEM',
          maxSortLevel,
          sortDirection: column.sort?.direction,
          sortLevel: column.sort?.level,
          sortByColumn,
        }}
        setColumnFilter={setColumnFilter}
        columnFilter={column.filter}
        isFilterDisabled={isFilterDisabled}
        distinctStringValues={distinctStringValueMap?.[column.key]}
        menuActions={getMenuActions(column.type)}
        isStringColumn={isStringColumn}
        thresholds={columnThresholds}
        textFormatter={textFormatter}
        moveColumn={moveColumn}
        removeColumn={removeColumn}
        darkMode={darkMode}
      />
    );
  });

  const renderTextCell = (row, column, cell, maybeStripedColor, key, columnIndex) => {
    return (
      <TableBuilderTextCell
        columnIndex={columnIndex}
        key={key}
        textValue={column.cells && column.cells[row.itemId]}
        style={computeCellStyle(
          column.backgroundColor,
          column.textColor,
          column.textStyle,
          column.textAlign,
          cell.priorityColor,
          maybeStripedColor,
          darkMode,
        )}
        onTextChange={(value) => setCellText(column.key, value, row.itemId)}
        canEditCellText={canEdit}
      />
    );
  };

  const renderDataCell = (row, column, cell, maybeStripedColor, key, columnIndex) => {
    return (
      <TableBuilderDataCell
        columnIndex={columnIndex}
        extraClassNames={cell.metricId ? 'cursorPointer text-underline-onhover' : ''}
        key={key}
        style={computeCellStyle(
          column.backgroundColor,
          column.textColor,
          column.textStyle,
          column.textAlign,
          cell.priorityColor,
          maybeStripedColor,
          darkMode,
        )}
        textValue={cell.value}
        showUnit={!showUnitInSeparateColumn || column.style === 'percent'}
        units={cell.units}
        onClick={(event) =>
          cell.metricId &&
          displayMetricOnTrend(
            cell.metricId,
            row.itemId,
            displayRange.start.valueOf(),
            displayRange.end.valueOf(),
            event,
          )
        }
        t={t}
      />
    );
  };

  const renderCell = (row, column, cell, maybeStripedColor, key, columnIndex) =>
    column.type === TableBuilderColumnType.Text
      ? renderTextCell(row, column, cell, maybeStripedColor, key, columnIndex)
      : renderDataCell(row, column, cell, maybeStripedColor, key, columnIndex);

  const createTransposedTableRows = () => {
    const rows = _.map(columns, (column, columnIndex) => {
      const headerProps = { ...textHeaders[columnIndex].props, key: 0 };

      const maybeStripedColor = getStripedColor(isStriped, columnIndex, darkMode);

      // allocate unique key values to avoid duplicate key error. start with next headerCell.key
      let key = headerProps.key + 1;
      const textCells: any = _.map(simpleTableData, (row) => {
        const cell = row.cells[columnIndex] ?? ({} as SimpleTableCell);
        const element = renderCell(row, column, cell, maybeStripedColor, key, columnIndex);
        key++;
        return element;
      });
      return (
        <TableBuilderHeaderRow key={columnIndex}>
          {headers.type !== TableBuilderHeaderType.None && <TableBuilderTextHeader {...headerProps} />}
          {textCells}
        </TableBuilderHeaderRow>
      );
    });

    if (!isLoading && _.isEmpty(simpleTableData)) {
      rows.push(<TableBuilderNoResultsRow key={columns.length} colspan={1} />);
    }

    return rows;
  };

  const createNonTransposedTableRows = () => {
    const rows = _.map(simpleTableData, (row, rowIndex) => {
      const maybeStripedColor = getStripedColor(isStriped, rowIndex, darkMode);
      const cells = _.map(columns, (column, columnIndex) => {
        const cell = row.cells[columnIndex] ?? ({} as SimpleTableCell);
        return renderCell(row, column, cell, maybeStripedColor, columnIndex, columnIndex);
      });
      return <TableBuilderRow key={rowIndex}>{cells}</TableBuilderRow>;
    });

    if (!isLoading && _.isEmpty(simpleTableData)) {
      rows.push(<TableBuilderNoResultsRow key={columns.length} colspan={columns.length} />);
    }

    return rows;
  };

  return (
    <DndProvider backend={HTML5Backend} context={window}>
      <table
        data-testid={isTransposed ? 'simpleTableTransposed' : 'simpleTable'}
        className={classNames(
          'table table-md-condensed width-auto screenshotSizeToContent',
          isTransposed ? 'table-bordered-first-column' : 'table-bordered',
          isTransposed ? 'fixedColumnTable' : 'fixedHeaderTable',
        )}>
        {isTransposed && <tbody>{createTransposedTableRows()}</tbody>}
        {!isTransposed && (
          <>
            <thead>
              {headers.type !== TableBuilderHeaderType.None && (
                <TableBuilderHeaderRow>{textHeaders}</TableBuilderHeaderRow>
              )}
            </thead>
            <tbody>{createNonTransposedTableRows()}</tbody>
          </>
        )}
      </table>
    </DndProvider>
  );
};
