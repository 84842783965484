import _ from 'lodash';
import classNames from 'classnames';
import React, { MouseEvent } from 'react';
import { NewLineToBr } from '@/core/NewLineToBr.atom';
import { NULL_PLACEHOLDER } from '@/utilities/tableBuilderHelper.utilities';
import { ENUM_REGEX } from '@/trendData/trendData.constants';
import { TFunction } from 'react-i18next';

interface DataCellIF {
  onClick?: (event: MouseEvent<any, any>) => void;
  textValue: string;
  showUnit?: boolean;
  units?: string;
  columnIndex: number;
  style: Object;
  extraClassNames?: string;
  key?: number;
  t: TFunction;
}

export const TableBuilderDataCell: React.FunctionComponent<DataCellIF> = (props) => {
  const { onClick = _.noop, textValue, showUnit = false, units, style, extraClassNames, t } = props;

  const getCellValue = () => {
    if (_.isNil(textValue)) {
      return '';
    } else if (_.includes(textValue, '\n')) {
      return <NewLineToBr lineToBreak={textValue} />;
    } else if (_.isBoolean(textValue)) {
      return t(`ADMIN.CONFIGURATION.${textValue ? 'LABEL_VALUE_TRUE' : 'LABEL_VALUE_FALSE'}`);
    }

    const match = textValue?.toString().match(ENUM_REGEX);
    if (match) {
      return match[2];
    }

    if (showUnit && !_.isEmpty(units) && textValue !== NULL_PLACEHOLDER) {
      return `${textValue} ${units}`;
    } else {
      return textValue;
    }
  };

  return (
    <td
      className={classNames('forceVerticalAlignMiddle nowrap', extraClassNames)}
      data-testid="tableBuilderDataCell"
      onClick={onClick}
      style={style}>
      {getCellValue()}
    </td>
  );
};
