import React from 'react';
import { Chart } from '@/tableBuilder/tableViewer/Chart.molecule';
import _ from 'lodash';
import { simpleTableToHighChartsData } from '@/tableBuilder/tableViewer/ChartUtilities';
import { ParametersMap } from '@/utilities/formula.constants';

interface TableViewerIF {
  simpleTableData: any[];
  columns: any[];
  storeSettings: any;
  testId: string;
  afterChartUpdate?: () => void;
  isTransposed: boolean;
  useSignalColorsInChart: boolean;
  chartColors: ParametersMap;
}

export const TableViewer: React.FunctionComponent<TableViewerIF> = ({
  simpleTableData,
  columns,
  storeSettings,
  testId,
  afterChartUpdate,
  isTransposed,
  useSignalColorsInChart,
  chartColors,
}) => {
  if (_.isEmpty(simpleTableData)) {
    return <div />;
  }

  const highchartsData = simpleTableToHighChartsData(
    simpleTableData,
    storeSettings,
    columns,
    isTransposed,
    useSignalColorsInChart,
    chartColors,
  );
  const { position, showSettings, ...settingsForChart } = storeSettings;

  return (
    <Chart
      tableData={highchartsData}
      chartColors={highchartsData.colors}
      settings={settingsForChart}
      testId={testId}
      afterChartUpdate={afterChartUpdate}
    />
  );
};
