// @ts-strict-ignore
import _ from 'lodash';
import React, { MouseEvent } from 'react';
import {
  ConditionTableData,
  ConditionTableHeader,
  TableBuilderHeaders,
  TableBuilderSort,
} from '@/tableBuilder/tableBuilder.store';
import { TableColumnFilter } from '@/core/tableUtilities/tables';
import { TableBuilderTextCell } from '@/tableBuilder/tableComponents/TableBuilderTextCell.atom';
import { TableBuilderDataCell } from '@/tableBuilder/tableComponents/TableBuilderDataCell.atom';
import { TableBuilderRow } from '@/tableBuilder/tableComponents/TableBuilderRow.molecule';
import { TableBuilderTextHeader } from '@/tableBuilder/tableComponents/TableBuilderTextHeader.atom';
import { TableBuilderDataHeader } from '@/tableBuilder/tableComponents/TableBuilderDataHeader.atom';
import { TableBuilderHeaderRow } from '@/tableBuilder/tableComponents/TableBuilderHeaderRow.molecule';
import { useTranslation } from 'react-i18next';
import { TextHeaderMenuActions } from '@/tableBuilder/tableComponents/TableBuilderTextHeaderMenu.atom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TableBuilderNoResultsRow } from '@/tableBuilder/TableBuilderNoResultsRow.atom';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import {
  computeCellColors,
  computeCellStyle,
  formatHeader,
  getStripedColor,
} from '@/utilities/tableBuilderHelper.utilities';
import {
  TableBuilderColumnType,
  TableBuilderHeaderType,
  TableTextFormatterIF,
} from '@/tableBuilder/tableBuilder.constants';
import { COLUMNS_AND_STATS } from '@/trendData/trendData.constants';

interface TableBuilderConditionTableIF {
  tableData: ConditionTableData;
  isLoading: boolean;
  headers: TableBuilderHeaders;
  columns: { propertyAndStatColumns: any[]; columns: any[] };
  isTransposed: boolean;
  isStriped: boolean;
  isPresentationMode: boolean;
  canEdit: boolean;
  timezone: { name: string };
  setCellText: (key: string, value: string, id?: string) => void;
  setHeaderText: (columnKey: string, text: string) => void;
  setColumnFilter: (key: string, filter: TableColumnFilter) => void;
  fetchStringColumnValues: (columnKey: string, isStringColumn: boolean, cancellationGroup: string) => void;
  itemFilters: { [id: string]: { filter: TableColumnFilter } };
  columnToThresholds: Object;
  distinctStringValueMap: { [columnKey: string]: string[] };
  textFormatter: TableTextFormatterIF;
  moveColumn: (key: string, newKey: string) => void;
  canSort: boolean;
  itemSorts: { [id: string]: TableBuilderSort };
  maxSortLevel: number;
  sortByColumn: (key: string, direction: string) => void;
  removeColumn: (key: string) => void;
  displayMetricOnTrend: (
    metricId: string,
    formulaItemId: string,
    start: number,
    end: number,
    event: MouseEvent,
  ) => void;
  isContent?: boolean;
  darkMode?: boolean;
}

export const TableBuilderConditionTable: React.FunctionComponent<TableBuilderConditionTableIF> = (props) => {
  const {
    tableData,
    isLoading,
    headers,
    columns: allColumns,
    isTransposed,
    isStriped,
    isPresentationMode,
    canEdit,
    timezone,
    setCellText,
    setHeaderText,
    setColumnFilter,
    fetchStringColumnValues,
    itemFilters,
    columnToThresholds,
    distinctStringValueMap,
    textFormatter,
    itemSorts,
    canSort,
    maxSortLevel,
    sortByColumn,
    moveColumn,
    removeColumn,
    displayMetricOnTrend,
    darkMode,
  } = props;
  const { propertyAndStatColumns, columns } = allColumns;

  const { t } = useTranslation();

  const showUnitInSeparateColumn = _.some(columns, (column) => column.key === COLUMNS_AND_STATS.valueUnitOfMeasure.key);
  const showHeaders = (canEdit && columns.length > 0) || headers?.type !== TableBuilderHeaderType.None;
  const isStartOrEndColumn = (column) =>
    _.includes([COLUMNS_AND_STATS.startTime.key, COLUMNS_AND_STATS.endTime.key], column?.key);

  const renderTextHeader = (column, columnIndex, key) => (
    <TableBuilderTextHeader
      textValue={column.header ?? (column.key === COLUMNS_AND_STATS.name.key ? '' : t(column.shortTitle))}
      isInput={true}
      key={key}
      columnIndex={columnIndex}
      columnKey={column.key}
      onTextChange={(value) => setCellText(column.key, value)}
      columnBackgroundColor={column.backgroundColor}
      columnTextAlign={column.textAlign}
      columnTextColor={column.textColor}
      columnTextStyle={column.textStyle}
      headerBackgroundColor={column.headerBackgroundColor}
      headerTextAlign={column.headerTextAlign}
      headerTextColor={column.headerTextColor}
      headerTextStyle={column.headerTextStyle}
      canEdit={canEdit}
      isTransposed={isTransposed}
      menuActions={getMenuActionsForTextHeader()}
      textFormatter={textFormatter}
      sort={{
        canSort,
        maxSortLevel,
        sortDirection: column.sort?.direction,
        sortLevel: column.sort?.level,
        sortByColumn,
      }}
      showMove={column.key !== COLUMNS_AND_STATS.name.key}
      moveColumn={moveColumn}
      removeColumn={removeColumn}
      fetchStringColumnValues={fetchStringColumnValues}
      darkMode={darkMode}
    />
  );

  const renderAlternateTextHeader = (header, column, statOrPropertyColumn, headerIndex, key) => (
    <TableBuilderTextHeader
      textValue={
        statOrPropertyColumn ? statOrPropertyColumn.header ?? header.name : getTextValueForHeader(header, column)
      }
      isInput={true}
      key={key}
      columnIndex={headerIndex}
      columnKey={statOrPropertyColumn?.key ?? header.key}
      onTextChange={(value) => statOrPropertyColumn && setHeaderText(statOrPropertyColumn.key, value)}
      headerBackgroundColor={column.backgroundColor}
      headerTextAlign={column.textAlign}
      headerTextColor={column.textColor}
      headerTextStyle={column.textStyle}
      canEdit={statOrPropertyColumn && canEdit}
      isTransposed={!isTransposed}
      isStringColumn={header.isStringColumn}
      isDurationColumn={statOrPropertyColumn?.key === SeeqNames.Properties.Duration}
      isFilterDisabled={isPresentationMode}
      distinctStringValues={distinctStringValueMap[statOrPropertyColumn?.key ?? header.key]}
      thresholds={columnToThresholds[header.key]}
      fetchStringColumnValues={fetchStringColumnValues}
      menuActions={getMenuActionsForAlternativeHeader(statOrPropertyColumn)}
      sort={{
        canSort,
        maxSortLevel,
        sortDirection: statOrPropertyColumn
          ? statOrPropertyColumn?.sort?.direction
          : itemSorts[header.key]?.sort?.direction,
        sortLevel: statOrPropertyColumn ? statOrPropertyColumn?.sort?.level : itemSorts[header.key]?.sort?.level,
        sortByColumn,
      }}
      setColumnFilter={setColumnFilter}
      columnFilter={statOrPropertyColumn ? statOrPropertyColumn.filter : itemFilters[header.key]?.filter}
      removeColumn={statOrPropertyColumn ? removeColumn : undefined}
      moveColumn={statOrPropertyColumn ? moveColumn : undefined}
      showMove={!isStartOrEndColumn(statOrPropertyColumn)}
      darkMode={darkMode}
    />
  );

  /**
   * Returns the TextHeaderMenuActions for standard text headers (for the Name/Unit of Measure columns)
   * Should include [Style, Rename, Remove] in edit mode OR an empty array (no actions) if not in edit mode
   */
  const getMenuActionsForTextHeader = () =>
    canEdit ? [TextHeaderMenuActions.Style, TextHeaderMenuActions.Rename, TextHeaderMenuActions.Remove] : [];

  /**
   * Returns the TextHeaderMenuActions for alternative text headers (for the metric/stat/property rows)
   * For metrics, should include [Filter] if we're not in presentation mode, or [] in presentation mode.
   * For stats/properties, should include [Rename, Filter, Remove] in edit mode, or [Filter] in view-only mode, or
   * nothing in presentation mode.
   *
   * @param [statOrPropertyColumn] - the stat or property column for the table column (optional)
   */
  const getMenuActionsForAlternativeHeader = (statOrPropertyColumn?) => {
    const menuActions = [];
    if (!isPresentationMode) {
      menuActions.push(TextHeaderMenuActions.Sort);
      if (!isStartOrEndColumn(statOrPropertyColumn)) {
        menuActions.push(TextHeaderMenuActions.Filter);
      }
    }
    if (statOrPropertyColumn && canEdit) {
      menuActions.push(TextHeaderMenuActions.Rename, TextHeaderMenuActions.Remove);
    }
    return menuActions;
  };

  const getTextValueForHeader = (header: ConditionTableHeader, column) =>
    _.cond([
      [_.matches({ type: TableBuilderColumnType.Text }), (column: any) => column.cells && column.cells[header.key]],
      [_.matches({ key: COLUMNS_AND_STATS.name.key }), () => header.name],
      [_.matches({ key: COLUMNS_AND_STATS.valueUnitOfMeasure.key }), () => header.units],
    ])(column);

  // Header row. One cell for each custom column and for each capsule
  const createHeaderRow = () => {
    let columnIndex = 0;
    const cells = _.map(columns, (column) => {
      const header = renderTextHeader(column, columnIndex, columnIndex);
      columnIndex++;
      return header;
    }).concat(
      _.map(tableData?.capsules, (capsule) => (
        <TableBuilderDataHeader
          headerValue={formatHeader(headers, capsule.property, capsule.startTime, capsule.endTime, timezone)}
          key={columnIndex++}
        />
      )),
    );

    return <TableBuilderHeaderRow>{cells}</TableBuilderHeaderRow>;
  };

  // Header rows for transposed version. One row per "column" that is enabled.
  const createTransposedHeaderRows = () =>
    _.map(columns, (row, headerIndex) => {
      let columnIndex = 0;
      const cells: JSX.Element[] = [];
      if (showHeaders) {
        cells.push(renderTextHeader(row, headerIndex, columnIndex));
        columnIndex++;
      }

      _.forEach(tableData.headers, (header) => {
        const propertyOrStatColumn = _.find(propertyAndStatColumns, {
          key: header.key,
        });
        cells.push(
          row.key === COLUMNS_AND_STATS.name.key ? (
            renderAlternateTextHeader(header, row, propertyOrStatColumn, columnIndex, columnIndex)
          ) : (
            <TableBuilderTextCell
              key={columnIndex}
              columnIndex={columnIndex}
              textValue={getTextValueForHeader(header, row)}
              onTextChange={(value) => setCellText(row.key, value, header.key)}
              style={computeCellStyle(
                row.backgroundColor,
                row.textColor,
                row.textStyle,
                row.textAlign,
                undefined,
                undefined,
                darkMode,
              )}
              canEditCellText={canEdit && row.type === TableBuilderColumnType.Text}
            />
          ),
        );
        columnIndex++;
      });

      return <TableBuilderHeaderRow key={headerIndex}>{cells}</TableBuilderHeaderRow>;
    });

  const renderDataCell = (header, capsule, value, maybeStripedColor, key, columnIndex) => (
    <TableBuilderDataCell
      onClick={(event: MouseEvent) =>
        value.formulaItemId &&
        displayMetricOnTrend(value.formulaItemId, value.itemId, capsule.startTime, capsule.endTime, event)
      }
      textValue={value.value}
      units={header.units}
      showUnit={!showUnitInSeparateColumn}
      columnIndex={columnIndex}
      key={key}
      extraClassNames={value.formulaItemId ? 'cursorPointer text-underline-onhover' : ''}
      style={computeCellColors(
        _.isNil(value.priorityColor) || value.priorityColor === '#ffffff' ? maybeStripedColor : value.priorityColor,
        darkMode,
      )}
      t={t}
    />
  );

  const renderTimeCell = (header, headers, capsule, maybeStripedColor, key, columnIndex) => (
    <TableBuilderDataCell
      textValue={formatHeader(
        {
          ...headers,
          type:
            header.key === COLUMNS_AND_STATS.startTime.key ? TableBuilderHeaderType.Start : TableBuilderHeaderType.End,
        },
        capsule.property,
        capsule.startTime,
        capsule.endTime,
        timezone,
      )}
      columnIndex={columnIndex}
      style={computeCellColors(maybeStripedColor, darkMode)}
      key={key}
      t={t}
    />
  );

  // Main table rows. One row per statistic with values per capsule
  const createTableRows = () => {
    const rows = _.map(tableData.headers, (header, rowIndex) => {
      const maybeStripedColor = getStripedColor(isStriped, rowIndex, darkMode);
      let columnIndex = 0;
      const propertyOrStatColumn = _.find(propertyAndStatColumns, {
        key: header.key,
      });
      const cells: JSX.Element[] = _.map(columns, (column) => {
        const cell =
          column.key === COLUMNS_AND_STATS.name.key ? (
            renderAlternateTextHeader(header, column, propertyOrStatColumn, rowIndex, columnIndex)
          ) : (
            <TableBuilderTextCell
              key={columnIndex}
              columnIndex={columnIndex}
              textValue={getTextValueForHeader(header, column)}
              onTextChange={(value) => setCellText(column.key, value, header.key)}
              style={computeCellStyle(
                column.backgroundColor,
                column.textColor,
                column.textStyle,
                column.textAlign,
                undefined,
                maybeStripedColor,
                darkMode,
              )}
              canEditCellText={canEdit && column.type === TableBuilderColumnType.Text}
            />
          );
        columnIndex++;
        return cell;
      }).concat(
        _.map(tableData.capsules, (capsule) => {
          const cell = isStartOrEndColumn(header)
            ? renderTimeCell(header, headers, capsule, maybeStripedColor, columnIndex, columnIndex)
            : renderDataCell(header, capsule, capsule.values[rowIndex], maybeStripedColor, columnIndex, columnIndex);
          columnIndex++;
          return cell;
        }),
      );

      return <TableBuilderRow key={rowIndex}>{cells}</TableBuilderRow>;
    });

    if (!isLoading && _.isEmpty(tableData.capsules)) {
      rows.push(<TableBuilderNoResultsRow key={tableData.headers.length} colspan={columns.length} />);
    }

    return rows;
  };

  // Main table rows for transposed table. One row per capsule.
  const createTransposedTableRows = () => {
    if (!isLoading && _.isEmpty(tableData.capsules)) {
      const colspan = (showHeaders ? 1 : 0) + tableData.headers.length;
      return <TableBuilderNoResultsRow colspan={colspan} />;
    }

    return _.map(tableData.capsules, (capsule, rowIndex) => {
      let columnIndex = 0;
      const maybeStripedColor = getStripedColor(isStriped, rowIndex, darkMode);
      const cells: JSX.Element[] = [];
      // Cell for capsule start/end
      if (showHeaders) {
        cells.push(
          <TableBuilderDataCell
            textValue={formatHeader(headers, capsule.property, capsule.startTime, capsule.endTime, timezone)}
            columnIndex={rowIndex}
            style={computeCellColors(maybeStripedColor, darkMode)}
            extraClassNames="text-bolder text-center"
            key={columnIndex++}
            t={t}
          />,
        );
      }

      // Cells for each property or statistic for the capsule
      _.forEach(capsule.values, (value, valueIndex) => {
        const header = tableData.headers[valueIndex];
        if (isStartOrEndColumn(header)) {
          cells.push(renderTimeCell(header, headers, capsule, maybeStripedColor, columnIndex, rowIndex));
        } else {
          cells.push(renderDataCell(header, capsule, value, maybeStripedColor, columnIndex, rowIndex));
        }
        columnIndex++;
      });
      return <TableBuilderRow key={rowIndex}>{cells}</TableBuilderRow>;
    });
  };

  const renderTable = () => (
    <table
      data-testid="conditionTable"
      className="table table-bordered-first-column table-md-condensed fixedColumnTable width-auto screenshotSizeToContent">
      {showHeaders && <thead>{createHeaderRow()}</thead>}
      <tbody>{createTableRows()}</tbody>
    </table>
  );

  const renderTransposedTable = () => (
    <table
      data-testid="conditionTableTransposed"
      className="table table-bordered table-md-condensed fixedHeaderTable width-auto screenshotSizeToContent">
      {columns.length > 0 && <thead>{createTransposedHeaderRows()}</thead>}
      <tbody>{createTransposedTableRows()}</tbody>
    </table>
  );

  return (
    <DndProvider backend={HTML5Backend} context={window}>
      {isTransposed ? renderTransposedTable() : renderTable()}
    </DndProvider>
  );
};
