import React from 'react';

export interface DataHeaderIF {
  headerValue: string;
  key?: number;
}

export const TableBuilderDataHeader: React.FunctionComponent<DataHeaderIF> = (props) => {
  const { headerValue } = props;

  return (
    <td
      className="text-bolder text-center forceVerticalAlignMiddle nowrap specDataColumnHeader"
      data-testid="tableBuilderDataHeader">
      {headerValue}
    </td>
  );
};
