import React from 'react';
import { useFlux } from '@/core/hooks/useFlux.hook';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { sqLicenseManagementStore, sqSystemWarningStore, sqWorkbenchStore } from '@/core/core.stores';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { setSystemMessageHash } from '@/workbench/workbench.actions';
import { Icon } from '@/core/Icon.atom';
import { Feature } from '@/licenseManagement/licenseManagement.store';
import { ContainerWithHTML } from '@/core/ContainerWithHTML.atom';
import { buildEmailLink } from '@/utilities/utilities';
import { disableSystemWarning, disableUserLimitWarning, setSystemMessage } from '@/systemWarning/systemWarning.actions';
import { adminContactEmail, adminContactName, auditingEnabled } from '@/services/systemConfiguration.utilities';
import { isProtractor } from '@/core/utilities';
import seeqLogoDarkBlueSm from '@/../public/img/Seeq_logo_darkBlue_sm.png';

export const SystemWarning: React.FunctionComponent<{}> = () => {
  const {
    warningComponents,
    systemMessage,
    showSystemWarning: showSystemWarningFromStore,
    nodeEnv,
  } = useFlux(sqSystemWarningStore);
  const currentUser = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.currentUser);
  const license = useFluxPath(sqLicenseManagementStore, () => sqLicenseManagementStore.license);
  const { t } = useTranslation();

  const isUserAdmin = currentUser?.isAdmin;
  const showSystemWarning =
    !_.isEmpty(warningComponents) &&
    showSystemWarningFromStore &&
    // TODO CRAB-12149 Should non-admins get hardware warnings?
    isUserAdmin;
  const isProduction = nodeEnv === 'production' && !isProtractor();
  const showSystemMessage = !!systemMessage && !sqWorkbenchStore.isSystemMessageDismissed(systemMessage);
  const showAuditWarning = !sqLicenseManagementStore.hasValidFeature(Feature.Audit_Trail) && auditingEnabled();

  const showUserLimitRestriction = license.userLimitRestrict && license.userCount >= license.userLimitRestrict;
  const showUserLimitWarning =
    !showUserLimitRestriction &&
    license.userLimitWarn &&
    license.userLimitRestrict &&
    license.userCount >= license.userLimitWarn &&
    !sqSystemWarningStore.suppressUserLimitWarning;

  const contactEmail = buildEmailLink(
    adminContactEmail(),
    adminContactName(),
    'SYSTEM_WARNINGS.INFO_TEXT_EMAIL_SUBJECT',
    'SYSTEM_WARNINGS.INFO_TEXT_EMAIL_BODY',
  );

  // Warning banner that informs about server hardware problems
  if (showSystemWarning && isProduction) {
    return (
      <div
        id="systemWarningBanner"
        data-testid="systemWarningBanner"
        className="pt20 pb20 flexColumnContainer alert-warning flexCenter">
        <div className="flexRowContainer">
          <p>
            <span>{t('LICENSE.THANKS_FOR_USING')}</span>
          </p>
          <p>
            {/* <!-- Main intro text if the user is not an admin. Tells them to email the admin. --> */}
            {!isUserAdmin && (
              <ContainerWithHTML
                testId="emailAdmin"
                content={t('SYSTEM_WARNINGS.INFO_TEXT_USER', {
                  CONTACT: contactEmail,
                })}
              />
            )}
            {isUserAdmin && (
              <>
                {/* <!-- Main intro text if the user is an admin. Tells them to improve HW. --> */}
                <span>{t('SYSTEM_WARNINGS.INFO_TEXT_ADMIN_1')}</span>&nbsp;
                <a
                  target="_blank"
                  href="https://telemetry.seeq.com/support-link/wiki/spaces/KB/pages/51806389/Installing+and+Upgrading+Seeq#InstallingandUpgradingSeeq-HardwareRequirements"
                  data-testid="seeqHardwareLink">
                  <span>{t('SYSTEM_WARNINGS.INFO_TEXT_ADMIN_2')}</span>
                </a>
                <span>:</span>
              </>
            )}
            &nbsp;
            {/* <!-- Lists the components that are causing the warning --> */}
            <span data-testid="warningComponents">{warningComponents.join(', ')}</span>
          </p>

          {/* <!-- Directs admins to the Administration view so they can see more details --> */}
          {isUserAdmin && (
            <a href="/administration">
              <span>{t('SYSTEM_WARNINGS.INFO_TEXT_ADMIN_MORE')}</span>
            </a>
          )}
        </div>
        <Icon
          testId="closeBanner"
          onClick={disableSystemWarning}
          icon="fa-times"
          large={true}
          extraClassNames="closeIcon mr20 ml10 flexSelfCenter"
        />
      </div>
    );
  }

  // Warning banner that displays a general message set by the system admin
  if (showSystemMessage) {
    return (
      <div
        id="systemMessageBanner"
        data-testid="systemMessageBanner"
        className="pt20 pb20 flexColumnContainer alert-warning flexCenter">
        <ContainerWithHTML id="systemMessageText" content={systemMessage} extraClassNames="flexSelfCenter" />
        <Icon
          icon="fa-times"
          testId="closeSystemMessageIcon"
          onClick={() => {
            setSystemMessage(null);
            setSystemMessageHash(systemMessage);
          }}
          large={true}
          extraClassNames="closeIcon mr20 ml10 flexSelfCenter"
          tooltip="CLOSE"
          tooltipPlacement="top"
        />
      </div>
    );
  }

  // Warning banner that displays a message if auditing is enabled without an appropriate license
  if (showAuditWarning) {
    return (
      <div
        id="auditWarningBanner"
        data-testid="auditWarningBanner"
        className="pt20 pb20 flexColumnContainer alert-warning flexCenter">
        <img src={seeqLogoDarkBlueSm} className="mr25 ml15" />
        <span id="auditWarningText" className="flexSelfCenter">
          {t('SYSTEM_WARNINGS.AUDIT')}
        </span>
      </div>
    );
  }

  // Warning banner that displays a message that the system is approaching the licensed user limit
  if (showUserLimitWarning) {
    return (
      <div
        id="userLimitWarningBanner"
        data-testid="userLimitWarningBanner"
        className="pt20 pb20 flexColumnContainer alert-warning flexCenter">
        <img src={seeqLogoDarkBlueSm} className="mr25 ml15" />
        <span id="userLimitWarningText" className="flexSelfCenter">
          {t('SYSTEM_WARNINGS.USER_LIMIT_WARNING', {
            count: license.userLimitRestrict,
            currentCount: license.userCount,
          })}
        </span>
        <Icon
          testId="closeUserLimitWarningIcon"
          onClick={disableUserLimitWarning}
          icon="fa-times"
          large={true}
          extraClassNames="closeIcon mr20 ml10 flexSelfCenter"
        />
      </div>
    );
  }

  // Warning banner that displays a message that the system is over the licensed user limit
  if (showUserLimitRestriction) {
    return (
      <div
        id="userLimitRestrictionBanner"
        data-testid="userLimitRestrictionBanner"
        className="pt20 pb20 flexColumnContainer alert-warning flexCenter">
        <img src={seeqLogoDarkBlueSm} className="mr25 ml15" />
        <span id="userLimitRestrictionText" data-testid="userLimitRestrictionText" className="flexSelfCenter">
          {t('SYSTEM_WARNINGS.USER_LIMIT_RESTRICTION', {
            count: license.userCount,
          })}
        </span>
      </div>
    );
  }

  return <></>;
};
